<template>
    <div class="candidate-details-tab__body">
        <div class="details__card">
            <div class="details__card__head d-flex">
                <h4>{{ $t("Resume")}}</h4>
                <a class="semi-button semi-button-info" v-if="hasCandidateResume" :href="candidateResumeUrl" target="_blank">{{$t(`Open resume`)}}...</a>
            </div>
            <div class="details__card__body">
                <iframe v-if="hasResumeAttachment" :src="candidate.user.resume_url" class="img-fluid w-100 resume-iframe"></iframe>

                <div v-else-if="hasResumeLink" class="resume-link">
                    <p>{{ $t('Candidate has provided following URL for resume:') }}</p>
                    <a :href="candidate.resume_link" target="_blank">{{ candidate.resume_link }}</a>
                    <p>{{ $t("You can click the 'Open Resume...' button to open it.") }}</p>
                </div>

                <template v-else>
                    <h4 class="empty-message">{{ $t("Resume not found")}}</h4>

                    <div v-if="!candidate.job_resume_required" class="resume-info">
                        <p><b>{{ $t('Note:') }}</b> {{ $t('This job post does not require any resume.') }}</p>
                        <template v-if="hasJobManagementPermission">
                            <p>{{ $t('Do you want next applicants should have resume? Please follow these steps:') }}</p>
                            <ol>
                                <li>{{ $t("Edit the job, then click 'Customize Fields'.") }}</li>
                                <li>{{ $t("Under 'Documents' box select 'Resume'.") }}</li>
                                <li>{{ $t("Then click 'Save & Continue' button.") }}</li>
                            </ol>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { canManagementJob } from "../../../config/permission";

    export default {
        props : ['candidate'],
        computed: {

            hasCandidateResume() {
                return !!(this.candidateResumeUrl && this.candidateResumeUrl.length > 0);
            },

            candidateResumeUrl() {
                return this.candidate.user.resume_url || this.candidate.resume_link;
            },

            hasResumeAttachment() {
                return !!this.candidate.user.resume_url;
            },

            hasResumeLink() {
                return !!this.candidate.resume_link;
            },

            hasJobManagementPermission() {
                return canManagementJob();
            }
        }
    }
</script>

<style scoped>

    .resume-iframe {
        height: 100vh;
    }

    .empty-message {
        margin: 0;
        padding: 0;
    }

    .resume-info {
        font-weight: normal;
        line-height: 30px;
        font-size: 14px;
        margin-top: 30px;
    }

    .resume-link {
        font-weight: normal;
        line-height: 30px;
        font-size: 14px;
    }


    .resume-link a {
        word-break: break-all;
        line-height: 1.4;
        display: inline-block;
        padding: 10px 0px;
    }

    .resume-info ol {
        padding-left: 15px;
    }
</style>
